body {
  font-family: 'Inter', sans-serif !important;
  word-wrap: break-word;
  background: url('../img/Wireframe-Pattern-Right.svg') bottom left no-repeat, url('../img/silhouette-master.svg') left no-repeat,
    url('../img/Wire-Frame-Pattern.svg') right top no-repeat, url('../img/Wireframe-Pattern-Right.svg') bottom right no-repeat;
}

.navbar {
  box-shadow: 0 2px 20px 0px rgb(0 0 0 / 10%);
  background-color: #fff;
}

nav.navbar div.float-right a {
  color: #10069f;
  background-color: rgba(42, 125, 225, 0.1);
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 48px;
  border: 2px solid transparent;
  transition: all ease 0.5s;
  font-size: 1.125rem;
  letter-spacing: 0;
  cursor: pointer;
  line-height: 18px;
}

nav.navbar div.float-right a:hover {
  border: 2px solid #10069f;
}

.main {
  margin-top: 5%;
  margin-bottom: 5%;
  height: 25%;
  border: 1px solid #e0e3e3;
  box-shadow: 0 0 25px #090b6f85;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
  padding: 50px;
  background: #fff;
}

.alert i {
  padding-right: 5px;
}

.button.button-icon {
  width: auto;
  background: #10069f;
  border: 1px solid #10069f;
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 1.25;
  padding: 1.0625rem 1.875rem;
  border-radius: 48px;
  transition: all ease 0.5s;
  position: relative;
  /* margin: 0 auto; */
  z-index: 1000;
  margin-right: 50px;
}

.button:hover {
  background: #0047ba;
  border-color: #0047ba;
}

.button a,
.button a:hover {
  color: #ffffff;
  text-decoration: none;
}

.button i {
  padding-left: 10px;
  position: relative;
  right: 0;
  transition: all ease 0.5s;
}
.button:hover i {
  right: -10px;
}

.button:nth-of-type(2) {
  width: auto;
  background: transparent;
  font-size: 1.1rem;
  line-height: 1.25;
  padding: 1.0625rem 1.875rem;
  border-radius: 48px;
  border: 1px solid #10069f;
  transition: all ease 0.5s;
}

.button:nth-of-type(2) a {
  color: #10069f;
  text-decoration: none;
}

.button:nth-of-type(2):hover a {
  color: #fff;
  text-decoration: none;
}

.button:nth-of-type(2):hover {
  color: #fff;
  text-decoration: none;
  background: #0047ba;
  border-color: #0047ba;
}

@media (max-width: 500px) {
  .button {
    font-size: 1rem;
    padding: 1rem 1rem;
  }
}
